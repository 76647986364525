import React from "react";
import { Link } from "gatsby";
import course1 from "../assets/images/course-1-1.jpg";
import team1 from "../assets/images/team-1-1.jpg";
import Img from "gatsby-image"

const ProjectsList = (data) => {
  console.log(data.data);
  console.log(data.data.length);

  return (
    <section className="blog-one blog-page">
      <div className="container">
        <div className="row">
          {data.data.map(nodo=>(
            <div key={nodo.id} className="col-lg-4">
              <div className="blog-one__single">
                <div className="blog-one__image">
                  <Img fluid={{ ...nodo.img.sharp.fluid, aspectRatio: 11 / 9 }} alt={nodo.title} />
                  <Link to={nodo.slug} className="blog-one__plus">
                    <i className="kipso-icon-plus-symbol"></i>
                  </Link>
                </div>
                <div className="blog-one__content text-center">
                  <h2 className="blog-one__title">
                    <Link to={nodo.slug}>
                      {nodo.title}
                    </Link>
                  </h2>
                  <p className="blog-one__text blog-one__resume text-justify">
                    {nodo.shortdescription}
                  </p>
                  <Link to={nodo.slug} className="blog-one__link">
                    Leer más
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsList;
