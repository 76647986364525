import React from 'react';
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Nav from "../components/Nav"
import ProjectsList from "../components/ProjectsList"
import useProyectos from "../Hooks/useProyectos"

const ProyectosPage = () => {
  const nodes = useProyectos();
  return (
    <Layout
      title="Rolteca | Proyectos"
      description="Aún no hemos desarrollado muchas aplicaciones, pero aqui tienes la lista y sus novedades"
      slug={"/proyectos"}>
      <Nav />
      <PageHeader title="Proyectos" />
      <ProjectsList
          data={nodes}/>
      <Footer />
    </Layout>
  );
};

export default ProyectosPage;
